<template>
  <div class="assignModal">
    <el-dialog
      :visible.sync="dialogVisible"
      width="977px"
      height="400px"
      top="100px"
      title="选择TA"
      @close="close"
    >
      <div style="text-align: center; font-size: 20px">
        <!-- <div class="modalTitle">选择TA</div> -->
        <div class="selectBox flexRow AlCenter">
          <div class="flex-1">
            <el-form class="flexRow">
              <div class="flex-1">
                <el-form-item label="HFM CODE" class="w100 pr24">
                  <el-select
                    clearable
                    v-model="selectEditMsg.hfmCode"
                    filterable
                    placeholder="请选择"
                    class="industry-selector"
                  >
                    <el-option
                      v-for="item in hfmCodeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="flex-1">
                <el-form-item label="Name/tel" class="w100 pr24">
                  <el-input
                    placeholder="请输入Name/tel"
                    v-model="selectEditMsg.userName"
                  >
                  </el-input
                  >       
                </el-form-item>
              </div>
            </el-form>
          </div>
          <div class="refreshBox">
            <el-button type="" class="" @click="refresh">刷新</el-button>
            <el-button
              class=""
              :loading="queryLoading"
              @click="getAssignPeople"
              type=""
              >查询</el-button
            >
          </div>
        </div>

        <PocTable
          v-loading="queryLoading"
          :data="tableData"
          stripe
          :current-page.sync="pageConfig.pageNum"
          :total="pageConfig.total"
          hidepage
          @size-change="pageSizeChange"
          @page-current-change="pageChange"
          :max-height="maxHeight"
          @selection-change="handleSelectionChange"
          ref="multipleTable"
          row-key="id"
        >
          <el-table-column
            type="selection"
            width="30"
            :reserve-selection="true"
          >
          </el-table-column>
          <el-table-column
            v-for="item in columnOptions"
            :key="item.key"
            :prop="item.key"
            :label="item.name"
            :fixed="item.fixed"
            :sortable="item.sortable"
            :width="item.width"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
        </PocTable>
      </div>
      <div style="text-align: right; padding: 20px 0 20px 0px">
        <el-button type="" @click="close">取 消</el-button>
        <el-button type="" class="hostButton" @click="sure">确 定</el-button>
        <div class="clear"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const { Vuex } = $PCommon
export default {
  name: 'userManage',
  mixins: [$PCommon.TableMixin],
  components: {},
  props: {
    selecthcIdIds: {
      type: String
    },
    fromType: {
      type: String
    }
  },

  data() {
    const h = document.getElementsByTagName('body')[0].clientHeight - 340 + 'px'
    return {
      maxHeight: h,
      tableData: [],
      hfmCodeList: [],
      dialogVisible: true,
      queryLoading: false,
      selectEditMsg: {
        userName: '',
        hfmCode: ''
      },
      columnConfig: [
        {
          key: 'hfmCode',
          name: 'HFM Code',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'userName',
          name: '用户名',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'roleName',
          name: '岗位',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'mobile',
          name: '手机号',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'email',
          name: '邮箱',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        }
      ]
    }
  },
  computed: {
    columnOptions() {
      return this.columnConfig.filter((item) => item.visible)
    }
  },

  async created() {
    console.log('父组件传参--', this.selecthcIdIds)
    await this.getHfmCode()
    if (this.fromType == 'staff') {
      await this.getAssignUser()
    } else {
      await this.getAssignPeople()
    }
  },
  methods: {
    async getAssignPeople() {
      this.queryLoading = true
      const { data, code } = await this.$Api.induction.getTaAssignPeople({
        ...this.selectEditMsg
      })
      if (code === 200) {
        this.tableData = data
        // this.pageConfig.total = data.total
        this.$forceUpdate()
      }
      this.queryLoading = false
    },

    async getAssignUser() {
      this.queryLoading = true
      const { data, code } = await this.$Api.staff.getAssignUser({
        ...this.selectEditMsg
      })
      if (code === 200) {
        this.tableData = data
        // this.pageConfig.total = data.total
        this.$forceUpdate()
      }
      this.queryLoading = false
    },
    async getHfmCode() {
      this.queryLoading = true
      const { data, code } = await this.$Api.induction.getHfmCode({})
      if (code === 200) {
        this.hfmCodeList = data
      }
      this.queryLoading = false
    },
    async sure() {
      this.queryLoading = true
      var APIUrl = ''
      if (this.fromType == 'staff') {
        const { data, code } = await this.$Api.staff.assignStaff({
          ids: this.selecthcIdIds,
          userId: this.selectIds
        })
        if (code === 200) {
          this.showMessage('successFlag')

          this.close()
        } else {
          this.showMessage('')
        }
        this.queryLoading = false
      } else {
        const { data, code } = await this.$Api.induction.assign({
          ids: this.selecthcIdIds,
          userId: this.selectIds
        })
        if (code === 200) {
          this.showMessage('successFlag')

          this.close()
        } else {
          this.showMessage('')
        }
        this.queryLoading = false
      }
    },
    showMessage(flag) {
      if (flag == 'successFlag') {
        this.$message({
          type: 'success',
          message: '分配成功'
        })
      } else {
        this.$message({
          type: 'error',
          message: '分配失败'
        })
      }
    },
    close() {
      this.dialogVisible = false
      this.$emit('closeModal')
    },
    refresh() {
      this.selectEditMsg = {
        userName: '',
        hfmcode: ''
      }
      this.getAssignPeople()
    },
    handleSelectionChange(val) {
      // 选的第一个
      if (val.length) {
        this.selectedItems = val
        this.selectIds = this.selectedItems.map((v) => v.id).join()
      } else {
        this.selectIds = ''
      }
    }
  },
  beforeDestroy() {},
  mounted() {}
}
</script>
<style lang="scss" scoped>
.w100 {
  width: 100%;
}
.pr24 {
  padding-right: 24px;
  box-sizing: border-box;
}
.modalTitle {
  width: 100%;
  padding-bottom: 20px;
  box-sizing: border-box;
  margin-bottom: 10px;
  border-bottom: 1px solid #777;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}
.refreshBox {
  button {
    height: 28px !important;
    margin-top: 16px !important;
  }
}

.flexRow {
  display: flex;
  flex-direction: row;
}
.flexCenter {
  justify-content: center;
  align-items: center;
}
.AlCenter {
  align-items: center;
}
.buttonunActive {
  width: 68px;
  height: 30px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ba9765;
  border-radius: 4px;
  color: #ba9765;
  font-size: 14px;
  line-height: 15px;
}
.buttonActive {
  width: 68px;
  height: 30px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 15px;
}
</style>
<style lang="scss">
.assignModal {
  .el-dialog__title {
    line-height: 24px;
    font-size: 21px;
    color: #303133;
  }
  .el-select {
    width: 100%;
  }
  .el-dialog__body {
    padding: 0px 20px 0px 20px !important;
  }
}
</style>
