<template>
  <!-- 查看详情区域 -->
  <div>
    <div class="scrollContainer">
      <div class="inductionTitleLab inductionTitleMsg">基本信息</div>

      <el-form class="filter-form rpm-query-from">
        <div class="flex flexBetw AlCenter">
          <div class="flex-1">
            <div class="flex flexBetw AlCenter flex-1 rowItemindex1">
              <div class="flex-1 flexRow">
                <div class="lableDetail">Number</div>
                <div class="lableContent">{{ selectEditMsg.number }}</div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Country</div>
                <div class="lableContent">{{ selectEditMsg.countryName }}</div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Labor Code</div>
                <el-tooltip
                  :content="selectEditMsg.laborCodeId"
                  placement="top"
                >
                  <div class="lableContent">
                    {{ selectEditMsg.laborCodeId }}
                  </div>
                </el-tooltip>
              </div>
            </div>
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-1 flexRow">
                <div class="lableDetail">Assigment/Project (Level2)</div>
                <div class="lableContent">
                  {{ selectEditMsg.assigmentProjectLevel2Name }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Description (Level3)</div>
                <el-tooltip
                  :content="selectEditMsg.descriptionLevel3Name"
                  placement="top"
                >
                  <div class="lableContent">
                    {{ selectEditMsg.descriptionLevel3Name }}
                  </div>
                </el-tooltip>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Client</div>
                <div class="lableContent">{{ selectEditMsg.client }}</div>
              </div>
            </div>
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-1 flexRow">
                <div class="lableDetail">HFM Code</div>
                <div class="lableContent">
                  {{ selectEditMsg.hfmCode }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Business Unit Name</div>
                <el-tooltip
                  :content="selectEditMsg.businessUnitName"
                  placement="top"
                >
                  <div class="lableContent">
                    {{ selectEditMsg.businessUnitName }}
                  </div>
                </el-tooltip>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Department</div>
                <div class="lableContent">
                  {{ selectEditMsg.departmentName }}
                </div>
              </div>
            </div>
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-1 flexRow">
                <div class="lableDetail">Work Location</div>
                <div class="lableContent">
                  {{ selectEditMsg.workingLocationId }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Job Title</div>
                <div class="lableContent">{{ selectEditMsg.jobTitle }}</div>
              </div>

              <div class="flex-1 flexRow">
                <div class="lableDetail">Estimated Start Date</div>
                <div class="lableContent">
                  {{ selectEditMsg.estimatedStartDate }}
                </div>
              </div>
            </div>
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-1 flexRow">
                <div class="lableDetail">Internal candidate or new hire</div>
                <div class="lableContent">
                  {{ selectEditMsg.internalCandidateOrNewHireId }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">New Candidate Name</div>
                <div class="lableContent">
                  {{ selectEditMsg.newCandidateName }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Legal Entity</div>
                <el-tooltip
                  :content="selectEditMsg.legalEntity"
                  placement="top"
                >
                  <div class="lableContent">
                    {{ selectEditMsg.legalEntity }}
                  </div>
                </el-tooltip>
              </div>
            </div>
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-1 flexRow">
                <div class="lableDetail">CEO</div>
                <div class="lableContent">
                  {{ selectEditMsg.ceoName }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">CEO-1</div>
                <div class="lableContent">
                  {{ selectEditMsg.ceoName1 }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Lion ID of LM</div>
                <div class="lableContent">
                  {{ selectEditMsg.lineManagerLiid }}
                </div>
              </div>
              <!-- <div class="flex-1 flexRow">
                <div class="lableDetail">LM Email</div>
                <div class="lableContent">
                  {{ selectEditMsg.lineManagerEmail }}
                </div>
              </div> -->
            </div>
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-1 flexRow">
                <div class="lableDetail">Name of LM</div>
                <div class="lableContent">
                  {{ selectEditMsg.lineManagerName }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">
                  Open less than 90Days or Freelance Utillized
                </div>
                <div class="lableContent">
                  {{ selectEditMsg.openLessThan90daysOrFreelanceUtillized }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">In Final Recruitment Stage</div>
                <div class="lableContent">
                  {{ selectEditMsg.inFinalRecruitmentStage }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form>
      <div>
        <div class="inductionTitleLab inductionTitleMsg">工作制&薪水</div>
        <el-form class="filter-form rpm-query-from">
          <div class="flex-1">
            <div class="flex flexBetw AlCenter flex-1 rowItemindex1">
              <div class="flex-1 flexRow">
                <div class="lableDetail">Work Schedules</div>
                <div class="lableContent">
                  {{ selectEditMsg.workSchedulesId }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Base Salary(Local Currency)</div>
                <div class="lableContent">{{ selectEditMsg.baseSalary }}</div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">是否十三薪</div>
                <div class="lableContent">
                  {{ selectEditMsg.thirteenSalary }}
                </div>
              </div>
            </div>
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-1 flexRow">
                <div class="lableDetail">Benefits（Local Currency）</div>
                <div class="lableContent">{{ selectEditMsg.benefits }}</div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Relocation Cost</div>
                <div class="lableContent">
                  {{ selectEditMsg.relocationCost }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">
                  Annual Total Cost (Local Currency)
                </div>
                <div class="lableContent">
                  {{ selectEditMsg.annualTotalCost }}
                </div>
              </div>
            </div>
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-1 flexRow">
                <div class="lableDetail">Euro Conv</div>
                <div class="lableContent">{{ selectEditMsg.euroConv }}</div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Annual Compensation (euros)</div>
                <div class="lableContent">
                  {{ selectEditMsg.annualCompensation }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">100%Billable</div>
                <div class="lableContent">
                  {{ selectEditMsg.bill100Percent }}
                </div>
              </div>
            </div>
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-1 flexRow">
                <div class="lableDetail">Budget within latest RF(Y/N)</div>
                <div class="lableContent">
                  {{ selectEditMsg.budgetWithinLastRf }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Hire Does Not Increase Capacity</div>
                <div class="lableContent">
                  {{ selectEditMsg.hireDoesNotIncreaseCapacity }}
                </div>
              </div>
              <div class="flex-1 flexRow"></div>
            </div>
          </div>
        </el-form>
      </div>
      <div>
        <div class="inductionTitleLab inductionTitleMsg">Fill Back</div>
        <el-form class="filter-form rpm-query-from">
          <div class="flex-1">
            <div class="flex flexBetw AlCenter flex-1 rowItemindex1">
              <div class="flex-1 flexRow">
                <div class="lableDetail">Type1</div>
                <div class="lableContent">{{ selectEditMsg.type1Id }}</div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">
                  If Backfill,name of departing employee
                </div>
                <div class="lableContent">
                  {{ selectEditMsg.ifBackfillNameOfDepartingEmployee }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">If Backfill,date of departure</div>
                <div class="lableContent">
                  {{ selectEditMsg.ifBackfillDateOfDeparture }}
                </div>
              </div>
            </div>
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-1 flexRow">
                <div class="lableDetail">
                  If Backfill,Departing Employee Annual Total Cost(Local
                  Currency)
                </div>
                <div class="lableContent">
                  {{ selectEditMsg.ifBackfillDepartingEmployeeAnnualTotalCost }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">
                  If backfill,departing Employee Annual Base Salary(Local
                  Currency)
                </div>
                <div class="lableContent">
                  {{ selectEditMsg.ifBackfillDepartingEmplyeeAnnualBaseSalary }}
                </div>
              </div>
              <div class="flex-1 flexRow"></div>
            </div>
          </div>
        </el-form>
      </div>
      <div>
        <div class="inductionTitleLab inductionTitleMsg">其他</div>
        <el-form class="filter-form rpm-query-from">
          <div class="flex-1">
            <div class="flex flexBetw AlCenter flex-1 rowItemindex1">
              <div class="flex-1 flexRow">
                <div class="lableDetail">Event Reason Name</div>
                <el-tooltip
                  :content="selectEditMsg.eventReasonName"
                  placement="top"
                >
                  <div class="lableContent">
                    {{ selectEditMsg.eventReasonName }}
                  </div>
                </el-tooltip>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Cost Center</div>
                <el-tooltip :content="selectEditMsg.costCenter" placement="top">
                  <div class="lableContent">{{ selectEditMsg.costCenter }}</div>
                </el-tooltip>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Job Classification</div>
                <el-tooltip
                  :content="selectEditMsg.jobClassification"
                  placement="top"
                >
                  <div class="lableContent">
                    {{ selectEditMsg.jobClassification }}
                  </div>
                </el-tooltip>
              </div>
            </div>
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-1 flexRow">
                <div class="lableDetail">RAF Approved Date</div>
                <div class="lableContent">
                  {{ selectEditMsg.rafApprovedDate }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Parent Function</div>
                <div class="lableContent">
                  {{ selectEditMsg.parentFunction }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Child Function</div>
                <div class="lableContent">
                  {{ selectEditMsg.childFunction }}
                </div>
              </div>
            </div>
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-1 flexRow">
                <div class="lableDetail">Relationship Effective start Date</div>
                <div class="lableContent">
                  {{ selectEditMsg.relationshipEffectiveStartDate }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Relationship Type</div>
                <div class="lableContent">
                  {{ selectEditMsg.relationshipType }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Adjust Budget</div>
                <div class="lableContent">{{ selectEditMsg.adjustBudget }}</div>
              </div>
            </div>
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-1 flexRow">
                <div class="lableDetail">
                  Business Case to Justify the Recruitment
                </div>

                <el-tooltip
                  :content="selectEditMsg.businessCaseToJustifyTheRecruitment"
                  placement="top"
                >
                  <div class="lableContent">
                    {{ selectEditMsg.businessCaseToJustifyTheRecruitment }}
                  </div>
                </el-tooltip>
              </div>
              <div class="flex-1 flexRow"></div>
              <div class="flex-1 flexRow"></div>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import scopeTransfer from '@/components/ScopeTransfer/ScopeTransfer'
export default {
  name: 'userManage',
  mixins: [$PCommon.TableMixin],
  components: {
    scopeTransfer
  },
  props: {
    jumpParams: {
      type: Object
    }
  },

  data() {
    const h = document.getElementsByTagName('body')[0].clientHeight - 360 + 'px'
    const cdate = this.$moment().format('YYYY-MM-DD')
    return {
      num: '',
      maxHeight: h,
      selectEditMsg: {}, // 详情信息
      statusList: [
        { id: 0, name: '启用' },
        { id: 1, name: '停用' }
      ],
      failturesList: [
        { id: 0, name: '未锁定' },
        { id: 1, name: '已锁定' }
      ],
      props: { multiple: true }
    }
  },
  computed: {},

  created() {
    this.getHcInfoMsg(this.jumpParams.hcId)
  },
  methods: {
    async getHcInfoMsg(hcId) {
      const { data, code, msg } = await this.$Api.induction.getHcInfoMsg({
        id: hcId
      })
      if (code === 200) {
        if (data.rafApprovedDate)
          data.rafApprovedDate = data.rafApprovedDate
            ? data.rafApprovedDate.slice(0, 10)
            : ''
        data.relationshipEffectiveStartDate =
          data.relationshipEffectiveStartDate
            ? data.relationshipEffectiveStartDate.slice(0, 10)
            : ''
        data.openLessThan90daysOrFreelanceUtillized =
          data.openLessThan90daysOrFreelanceUtillized ? '是' : '否'
        data.inFinalRecruitmentStage = data.inFinalRecruitmentStage
          ? '是'
          : '否'
        data.budgetWithinLastRf = data.budgetWithinLastRf ? '是' : '否'
        data.thirteenSalary = data.thirteenSalary ? '是' : '否'
        data.hireDoesNotIncreaseCapacity = data.hireDoesNotIncreaseCapacity
          ? '是'
          : '否'
        data.bill100Percent = data.bill100Percent ? '是' : '否'
        data.estimatedStartDate = data.estimatedStartDate
          ? data.estimatedStartDate.slice(0, 10)
          : ''

        this.selectEditMsg = data
      } else {
        this.$message({
          type: 'error',
          message: msg
        })
      }
    }
    // // 关闭侧边栏
    // cancle() {
    //   this.$emit('goback')
    // }
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {})
    window.removeEventListener('keyup', () => {})
  },
  mounted() {
    window.addEventListener('keyup', (e) => {
      if ((e.code == 'Enter' || e.target == 13) && e.target.id == 'inputid') {
        console.log(e)
        this.filterQuery()
      }
    })
    window.addEventListener('resize', () => {
      const h =
        document.getElementsByTagName('body')[0].clientHeight - 360 + 'px'
      this.maxHeight = h
    })
  }
}
</script>
<style lang="scss" scoped>
.rowItemindex1 {
  margin-top: 20px;
}
.rowItem {
  margin-top: 12px;
}
.scrollContainer {
  // height: calc(100vh - 200px) !important ;
  overflow: auto;
  padding-bottom: 20px;
  box-sizing: border-box;
  min-width: 900px;
}
.inductionTitleMsg {
  font-size: 16px;
  color: #303133;
  font-weight: bold;
  padding: 20px 0 2px 0px;
  box-sizing: border-box;
}
.lableDetail {
    width: 140px;
    color: #868686;
    // font-family: PingFangSC-Regular, PingFang SC;
    /* width: -webkit-fit-content; */
    width: -moz-fit-content;
    /* word-break: break-all; */
    // max-width: 70%;
    //  border: 1px solid red;
}
.lableContent {
  // border: 1px solid red;
  color: #1B1D1E;
  margin-left: 12px;
  font-weight: 500;
  width: 100%;
  // max-width: 58%;

  overflow: hidden;
  text-overflow: ellipsis;
  // white-space: nowrap;
}
.lableContent span {
  color: #1b1d1e;
  margin-left: 12px;

}


.flexRow {
  width: 100%;
  overflow: hidden;
  margin-right: 24px;
  // background: red;
}
</style>
