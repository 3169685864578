<template>
  <div class="editInduction-list-page">
    <PocCardTable
      class="irisePocCardTable detailPageContainer"
      v-loading="saveLoadding"
    >
      <template v-slot:headerTitle>
        <span style="font-weight: 400"> 招聘管理</span>
      </template>
      <template v-slot:headerGroup>
        <el-button v-if="chooseTab == '2'" @click="offerPreview">预览</el-button>
        <el-button @click="goback('goBack')">取 消</el-button>
        <el-button
          v-if="chooseTab == '2'"
          type="primary"
          class="hostButton"
          @click="save"
          >保 存</el-button
        >
      </template>
      <div class="editContainer">
        <template>
          <!-- <div style="font-size: 0" class="guidao" >
            <img class="guidaoImg" src="@/assets/img/guidao.png" alt="" />
          </div> -->
          <div class="flexRow guidaoContent">
            <div
              class="scopeItem"
              v-loading="loading"
              :class="chooseTab == i ? 'active' : 'unactive'"
              v-for="i in [1, 2]"
              :key="'key' + i"
              @click="changeTab(i)"
            >
              {{ tabLabel[i] }}
              <div
                style="font-size: 0"
                class="guidaoactive"
                v-if="chooseTab == i"
              >
                <img
                  class="guidaoactiveImg"
                  src="@/assets/img/guidaoactive.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div style="font-size: 0" class="guidao">
            <img class="guidaoImg" src="@/assets/img/guidao.png" alt="" />
          </div>

          <!-- 详情的区域 -->
          <induction-msg-manage
            v-if="jumpParams.jumpFlag == 'goDetail'"
            :jumpParams="jumpParams"
            @goback="goback"
          />
          <!--          <EmploymentHistory-->
          <!--            v-else-if="jumpParams.jumpFlag === 'EmploymentHistory'"-->
          <!--            :jumpParams="jumpParams"-->
          <!--            @goback="goback"-->
          <!--          />-->
          <!-- 编辑区域 -->
          <edit-able-manage
            v-else
            ref="inductionPersonFormRef"
            :jumpParams="jumpParams"
            @goback="goback"
            @temporarySave="temporarySave"
            @changedData="changedData"
          />
        </template>
      </div>
    </PocCardTable>
    <el-dialog
      :title="dailogTitle"
      :visible.sync="dialogVisible"
      width="50%"
      class="PersonnelSelectionDialog"
      append-to-body
      destroy-on-close
      :before-close="close"
    >
      <div class="PersonnelSelectionDialog__title">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item :label="dailogContent">
            <el-select v-model="offerType" clearable placeholder="">
              <el-option
                v-for="item in offerDemoTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="preview">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { getTheyearOptions, stampToTime } from '@/utils/util'
const { Vuex } = $PCommon
const { mapGetters } = Vuex
import InductionMsgManage from './InductionMsgManage.vue'
import editAbleManage from './editAbleManage.vue'
import EmploymentHistory from '@/views/inductionManage/components/EmploymentHistory'
const { PocResetMessage, $sessionStorage } = $PCommon
export default {
  name: 'userManage',
  mixins: [$PCommon.TableMixin],
  components: {
    InductionMsgManage,
    editAbleManage,
    EmploymentHistory
  },
  props: {
    jumpParams: {
      type: Object
    }
  },

  data() {
    const h = document.getElementsByTagName('body')[0].clientHeight - 360 + 'px'
    const cdate = this.$moment().format('YYYY-MM-DD')
    return {
      maxHeight: h,
      queryLoading: false,
      selectEditMsg: {
        assignedGroupList: '',
        assignedRoleList: '',
        beginTime: '',
        email: '',
        endTime: '',
        mobile: '',
        remark: '',
        status: '',
        userCode: '',
        userName: ''
      }, // 选中修改的一系列用户信息
      statusList: [
        { id: 0, name: '启用' },
        { id: 1, name: '停用' }
      ],
      failturesList: [
        { id: 0, name: '未锁定' },
        { id: 1, name: '已锁定' }
      ],
      jumpFlag: '',
      tabLabel: {
        1: 'Hiring Brief Info',
        2: 'Offer Proposal',
        3: 'Employment History'
      },
      chooseTab: 1,
      ifEditFlag: false,
      clickSaveFlag: false, //初始化
      props: { multiple: true },
      cancelStatus: false,
      saveLoadding: false,
      changed:false,
      dailogTitle: '',
      dialogVisible: false,
      offerType: '',
      dailogContent: '',
      selectdofferProposalId: '',
      offerDemoTypeList: [
        { id: '1', name: 'PG China Offer Letter Template - Local - General' },
        {
          id: '2',
          name: 'PG China Offer Letter Template - Local - Target Bonus'
        },
        {
          id: '3',
          name: 'PG China Offer Letter Template - Local - No probation'
        },
        { id: '4', name: 'PG China Offer Letter Template - Expat  - General' },
        {
          id: '5',
          name: 'PG China Offer Letter Template- Expat - General -Target Bonus'
        },
        {
          id: '6',
          name: 'PG China Offer Letter Template - Expat - No probation'
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    theyearOptions() {
      // 获取年份列表
      return getTheyearOptions()
    }
  },
  destroyed() {
    this.changed = false
  },
  created() {
    if (this.jumpParams.jumpFlag == 'edit') {
      this.chooseTab = '2'
    }
    if (
      this.jumpParams.offerStatusNumber == '8' ||
      this.jumpParams.offerStatusNumber == '9' ||
      this.jumpParams.offerStatusNumber == '0' ||
      !this.jumpParams.offerStatusNumber
    ) {
      this.ifEditFlag = false
      this.jumpParams.employmentHistory = 'details'
    } else {
      this.ifEditFlag = true
    }
  },
  methods: {
    changedData(changed){
      this.changed = changed
    },
    offerPreview(){
      if(this.changed){
        this.$message.error("请先保存offer信息")
        return
      }
      this.dailogTitle = 'offer模板'
      this.dailogContent = '请选择offer模板'
      this.dialogVisible = true
    },
    preview(){
      let params = {
        "offerId": this.jumpParams.offerProposalId,
        "templateId": this.offerType
      }
      this.saveLoadding = true
      this.$Api.induction.offerPreview(params)
        .then((res)=>{
          if(res.code === 200){
            window.open(res.msg)
          }
          console.log(res);
          this.saveLoadding = false

        })
    },
    close() {
      this.dialogVisible = false
    },
    changeTab(activeTab) {
      console.log('activeTab', activeTab)
      this.chooseTab = activeTab
      if (this.chooseTab == '1') {
        this.goDetail()
      } else {
        if (!this.saveLoadding) {
          this.goEdit()
        }
      }
    },
    goEdit() {
      this.jumpParams.jumpFlag = 'edit'
    },
    goDetail() {
      this.jumpParams.jumpFlag = 'goDetail'
    },
    goback(val = '') {
      if (val === 'goBack') {
        this.cancelStatus = true
      }
      this.changed = false
      this.$emit('goback', val)
    },
    save() {
      console.log('提交了')
      this.clickSaveFlag = true
      let checkData = this.$refs.inductionPersonFormRef.checkData()
      checkData.then((v) => {
        if (v) {
          this.submit()
        } else {
          this.$message({
            type: 'error',
            message: '校验失败!'
          })
        }
      })
    },
    async submit() {
      this.saveLoadding = true
      let selectEditMsg = this.$refs.inductionPersonFormRef.getData()
      // if (!selectEditMsg.id) {
      //   return
      // }
      const data = await this.$Api.induction.editInduction({
        ...selectEditMsg
      })

      this.buttonDisable = false
      this.saveLoadding = false
      if (data && data.code === 200) {
        if (this.clickSaveFlag) {
          this.changed = false
          this.$message({
            type: 'success',
            message: '保存成功'
          })
        } else {
        }

        // this.clickSaveFlag=false;
        if (this.clickSaveFlag) {
          this.$emit('goback', 'bug-IRIS2-1024')
        }
      } else {
        this.$message({
          type: 'info',
          message: '保存失败'
        })
      }
    },
    temporarySave() {
      let offerStatusNumber = this.jumpParams.offerStatusNumber
      if (
        offerStatusNumber == '0' ||
        offerStatusNumber == '8' ||
        offerStatusNumber == '9'
      ) {
        console.log('-------------', this.clickSaveFlag)
        if (!this.clickSaveFlag && !this.cancelStatus) {
          this.submit()
        }
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {})
    window.removeEventListener('keyup', () => {})
  },
  mounted() {
    window.addEventListener('keyup', (e) => {
      if ((e.code == 'Enter' || e.target == 13) && e.target.id == 'inputid') {
        console.log(e)
        this.filterQuery()
      }
    })
    window.addEventListener('resize', () => {
      const h =
        document.getElementsByTagName('body')[0].clientHeight - 360 + 'px'
      this.maxHeight = h
    })
  }
}
</script>
<style lang="scss" scoped>
.w100 {
  width: 100%;
}
.pr24 {
  padding-right: 24px;
  box-sizing: border-box;
}
.editContainer {
  width: 100%;
  padding: 0px 10px 0 10px;
  box-sizing: border-box;
  // border: 1px solid red;
}
.buttonBottom {
  justify-content: flex-end;
  margin-right: 150px;
  margin-top: 20px;
  .el-button--mini {
    margin-right: 50px;
  }
}

.flexRow {
  display: flex;
  flex-direction: row;
}
.flexCenter {
  justify-content: center;
  align-items: center;
}
.AlCenter {
  align-items: center;
}
.flexBetw {
  display: flex;
  justify-content: space-between;
}
.upContainer {
  width: 90px;
  margin-top: 7px;
  justify-content: flex-end;
}
.buttonBottom {
  justify-content: flex-end;
  margin-right: 150px;
  margin-top: 20px;
  .el-button--mini {
    margin-right: 50px;
  }
}
.bottomButton {
  width: 180px;
  padding: 20px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  right: 0;
}
.bottomBox {
  width: 180px;
  padding: 20px;
  box-sizing: border-box;
  z-index: 99;
  position: fixed;
  bottom: 0;
  right: 20px;
}
.flex {
  display: flex;
}
.flexBetw {
  display: flex;
  justify-content: space-between;
}

.buttonunActive1 {
  width: 68px;
  height: 30px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 15px;
}
.buttonunActive {
  width: 132px;
  height: 30px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ba9765;
  border-radius: 4px;
  color: #ba9765;
  font-size: 14px;
  line-height: 15px;
}
.lableDetail {
  color: #4e4e50;
  font-size: 14px;
  font-weight: 400;
  // font-family: PingFangSC-Regular, PingFang SC;
}
.lableContent {
  color: #545454;
  font-size: 14px;
  // font-weight: 300;
  margin-left: 24px;
  // font-family: PingFangSC-Regular, PingFang SC;
}
.lableContent span {
  color: #545454;
  font-size: 14px;
  // font-family: PingFangSC-Regular, PingFang SC;
}
.scopeItem {
  width: 151px;
  // margin-right: 72px;
  color: #828282;
  font-size: 14px;
  font-weight: 400;
  // border: 1px solid red;
  text-align: center;
  margin-top: 16px;
}
.active {
  color: #303133;
  text-align: center;
  font-weight: 600;
  // border: 1px solid red;
}
.unactive {
  color: #545454;
  text-align: center;
}
.scopeContainer {
  margin-top: 23px;
}
.guidaoactive {
  padding-top: 8px;
  box-sizing: border-box;
  width: 151px;
  // height: 2px;
  overflow: hidden;
}
.guidao {
  font-size: 0;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  .guidaoImg {
    overflow: hidden;
    height: 1px;
  }
}
.guidaoactiveImg {
  width: 100%;
  height: 2px;
}
.guidaoContent {
  padding: 3px 0 0 3px;
}
</style>
<style lang="scss">
.editInduction-list-page {
  // .el-icon-minus:before {
  //   content: '';
  //   transform: rotate(180deg);
  // }
  // .el-icon-plus:before {
  //   content: '';
  // }
}
</style>
