import {checkPhone} from "@/utils/util";

let requiredArr = [
  'candidateName',
  'candidateEnglishName',
  'candidatePhone',
  'candidateEmail',
  'country',
  'expatLocal',
  'proposedOnboardDate',
  'candidatePreviousCompanyCategoryId',
  // 'offeredMonthlySalaryDuringProbtion',
  // 'offeredMonthlySalaryAfterProbtion',
  'annualLeaveDays',
  'probationPeriodMonthly',
  'monthlyTotalAllowance',
  // 'idNumber',
  'idType',

  'discretionaryBonusPlan',
  'workSchedules',

  // 'titleCnDuringProbation',
  // 'titleCnAfterProbation',
  // 'titleEnDuringProbation',
  // 'titleEnAfterProbation',
  // 'assessmentPeriod',
  // 'titleCnDuringAssessment',
  // 'titleCnAfterAssessment',
  // 'titleEnDuringAssessment',
  'contractPeriod',
  'noticePeriod',
  // 'noticePeriodWithinProbation',
  // 'titleEnAfterAssessment',
  'freshGraduate',
  'homeCity',
  'maximumMovingCost',
  'maximumTemporaryAccommodationCost',
  'withSpouse',
  'workingLocation',
  'additionalCommercialInsurance',
  'forEmployeeOnly',
  'insuranceRelationship',
  'lineManagerLiid',
  'lineManagerEmail',
  'lineManagerName',
  'thirteenSalary',
  'recruitmentChannel',
  'jobGrading',
  'annualPackage',
  'contractualBonusPlan',
]

//身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
var validateIdNumber = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('身份证未输入'))
  } else {
    const idNumberReg = RegExp(/(^\d{15}$)|(^\d{17}(\d|X|x)$)/) //
    if (idNumberReg.test(value) === false) {
      callback(new Error('身份证格式不正确'))
    }
    callback()
  }
}
var validatePhone = (rule, value, callback) => {
  var res = checkPhone(value);
  if (!res) {
    callback(new Error('手机格式不正确'));
  }
  callback();
}
var candidateEnglishName = (rule, value, callback) => {
  if (!value) {
    callback(new Error('candidate English Name为空'))
  } else {
    const chineseReg = RegExp(/[\u4E00-\u9FA5]/g)
    if (chineseReg.test(value)) {
      callback(new Error('输入不可为中文'))
    }
    callback()
  }
}
var candidateEmail = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('邮箱为空'))
  } else {
    const regEmail =
      /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/
    if (!regEmail.test(value)) {
      callback(new Error('邮箱规范不正确'))
    }
    callback()
  }
}
export const rules = {}
export const checkIdCard = () => {
  return [{ validator: validateIdNumber, required: true, trigger: 'blur' }]
}
requiredArr.forEach((item) => {
  rules[item] = [{ required: true, trigger: 'blur' }]
  if (item == 'candidatePhone') {
    rules[item] = [
      { validator: validatePhone, required: true, trigger: 'blur' }
    ]
  }
  if (item == 'candidateEnglishName') {
    rules[item] = [
      { validator: candidateEnglishName, required: true, trigger: 'blur' }
    ]
  }
  if (item == 'candidateEmail') {
    rules[item] = [
      { validator: candidateEmail, required: true, trigger: 'blur' }
    ]
  }
  if (item == 'idNumber') {
    rules[item] = checkIdCard()
  }
})
