<template>
  <div>
    <div v-show="!manageShow" class="induction-list-page">
      <PocCardTable class="topPocCardTable">
        <template v-slot:headerTitle>招聘管理</template>
        <template v-slot:headerGroup>
          <el-button class="buttonrefresh" @click="refresh">
            <i class="el-icon-refresh-left buttonrefreshSpan"></i>
          </el-button>
          <el-button
            class="buttonUnactive lastButton"
            role="export"
            :loading="queryLoading"
            @click="filterQuery"
            >查询</el-button
          >
        </template>
        <PocSlider minheight="60px" maxHeight="350px" btntype="primary">
          <el-form
            :inline="true"
            :model="formInline"
            label-position="top"
            class="el-form elformInputBox minWidthInput"
          >
            <el-form-item label="Candidate Name">
              <el-input
                placeholder="请输入Candidate Name"
                v-model="formInline.candidateName"
              >
              </el-input
              >
            </el-form-item>

            <el-form-item label=" Assigment/project (Level2)">
              <el-select
                clearable
                v-model="formInline.assigmentProjectLevel2"
                filterable
                placeholder="请选择"
                class="industry-selector"
              >

                <el-option
                  v-for="item in assigmentProjectLevel2List"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>

              </el-select>

            </el-form-item>
             <el-form-item label="Description（Level3）">
              <el-select
                clearable
                v-model="formInline.descriptionLevel3"
                filterable
                placeholder="请选择"
                class="industry-selector"
              >
                <el-option
                  v-for="item in descriptionLevel3List"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Creation User">

              <el-input
                placeholder="请输入Creation User"
                v-model="formInline.createUser"
              >
                                      </el-input
              >
            </el-form-item>

            <el-form-item label="Creation Date"
              >
              <el-date-picker
                style="width: 100%"
                v-model="dateRange2"
                type="daterange"
                align="right"
                unlink-panels
                :clearable="true"
                range-separator="-"
                start-placeholder="Start Date"
                end-placeholder="End Date"
                value-format="yyyy-MM-dd"
                @change="onFilter2"
              >

              </el-date-picker>

            </el-form-item>
            <!-- <el-form-item label="Client">
                 <el-input
                  placeholder="请输入Client"
                  v-model="formInline.client">
              </el-input>
              </el-form-item> -->
<!--            <el-form-item label="Leaver Name">-->
<!--              <el-input-->
<!--                placeholder="请输入Leaver Name"-->
<!--                v-model="formInline.leaverName"-->
<!--              >-->
<!--              </el-input>-->
<!--                                         -->
<!--            </el-form-item>-->
            <el-form-item label="Budget Monthly Salary">
              <el-input
                placeholder="请输入Budget Monthly Salary"
                v-model="formInline.budgetMonthlySalary"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="Replacement/NewHC">
              <el-input
                placeholder="请输入Replacement/NewHC"
                v-model="formInline.replacement"
              >
              </el-input>
            </el-form-item>
               <el-form-item label="Leaver Name"
              >
              <el-input
                placeholder="请输入Leaver Name"
                v-model="formInline.leaverName"
              >
              </el-input>

            </el-form-item>
            <el-form-item label="Department"
              >
              <el-select
                clearable
                v-model="formInline.departmentId"
                filterable
                placeholder="请选择"
                class="industry-selector"
              >
                <el-option
                  v-for="item in departmentIdList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>

              </el-select>

            </el-form-item>
               <el-form-item label="In Hand"
              >
              <el-select
                clearable
                v-model="formInline.inHand"
                filterable
                placeholder="请选择"
                class="industry-selector"
              >
                <el-option
                  v-for="item in handDataList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Business Unit">
              <el-select
                clearable
                v-model="formInline.businessUnitId"
                filterable
                placeholder="请选择"
                class="industry-selector"
              >
                <el-option
                  v-for="item in businessUnitIdList"
                  :key="item.businessUnitId"
                  :label="item.businessUnitName"
                  :value="item.businessUnitId"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="RAF Approved Date">
              <el-date-picker
                style="width: 100%"
                v-model="dateRange"
                type="daterange"
                align="right"
                unlink-panels
                :clearable="true"
                range-separator="-"
                start-placeholder="Start Date"
                end-placeholder="End Date"
                value-format="yyyy-MM-dd"
                @change="onFilter"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="Offer Status">
              <el-select
                clearable
                :disabled="statusDisableFlag"
                v-model="formInline.offerStatus"
                filterable
                placeholder="请选择"
                class="industry-selector"
              >
                <el-option
                  v-for="item in OfferStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Title EN">
              <el-input
                placeholder="请输入Title EN"
                v-model="formInline.titleEn"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="Job Title">
              <el-input
                placeholder="请输入Job Title"
                v-model="formInline.jobTitle"
              >
              </el-input
              >
            </el-form-item>
            <!-- <el-form-item label="Request ID">
              <el-input
                placeholder="请输入Request ID"
                v-model="formInline.requestId"
              >
              </el-input>
            </el-form-item> -->
            <el-form-item label="Client">
              <el-input placeholder="请输入Client" v-model="formInline.client">
              </el-input>
            </el-form-item>
            <el-form-item label="HC number">
              <el-input placeholder="请输入HC number" v-model="formInline.hcNumber">
              </el-input>
            </el-form-item>
          </el-form>
        </PocSlider>
      </PocCardTable>
      <PocCardTable>
        <!-- 操作按钮区域 -->
        <div class="flex opeartionBox">
          <!-- <div
            class="flexRow circleBox"
            v-for="i in [1, 2, 3, 4]"
            :key="'key' + i"
            @click="changeTab(i)"
          >
            <div class="circle" :class="i == 1 ? ' circleNoPadding' : ''">
              <div :class="chooseTab == i ? ' circleCentent' : ''"></div>
            </div>
            <div class="circleTab">{{ tabLabel[i] }}</div>
          </div> -->

          <el-radio-group @change="changeTab" v-model="chooseTab">
            <el-radio :label="i" v-for="i in [1, 2, 3, 4]" :key="'key' + i">{{
              tabLabel[i]
            }}</el-radio>
          </el-radio-group>
        </div>
        <div class="buttonBox">
          <!-- <el-checkbox
            v-model="checked"
            @change="selectAll"
            style="margin-right: 12px"
            >全选</el-checkbox
          > -->
          <el-button
            :loading="backLoading"
            role="back"
            v-permission
            @click="backBP"
            >收回</el-button
          >
          <el-button
            :loading="queryLoading"
            @click="assign"
            role="distribution"
            v-permission
            >分配</el-button
          >
          <el-button
            type="primary"
            class="buttonActive"
            @click="submitBP"
            role="submit"
            v-permission
            >提交</el-button
          >
        </div>
        <PocTable
          v-loading="queryLoading"
          :data="tableData"
          size="large"
          stripe
          :current-page.sync="pageConfig.pageNum"
          :total="pageConfig.total"
          @size-change="pageSizeChange"
          @page-current-change="pageChange"
          :max-height="1000000000"
          @selection-change="handleSelectionChange"
          @row-click="goDetail"
          ref="multipleTable"
          row-key="offerProposalId"
        >
          <el-table-column type="selection" width="45"> </el-table-column>
          <el-table-column
            v-for="item in columnOptions"
            :key="item.key"
            :prop="item.key"
            :label="item.name"
            :fixed="item.fixed"
            :sortable="item.sortable"
            :width="item.width"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            label="Operations"
            width="88"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-row
                :gutter="24"
                style="display: flex; justify-content: center"
              >
                <el-col>
                  <el-tooltip
                    content="编辑"
                    placement="top"
                    v-if="
                      scope.row.offerStatusNumber == '8' ||
                      scope.row.offerStatusNumber == '9' ||
                      scope.row.offerStatusNumber == '0' ||
                      scope.row.offerStatusNumber == '2' ||
                      !scope.row.offerStatusNumber
                    "
                  >
                    <img
                      class="iconImg pointer"
                      role="edit"
                      v-permission
                      src="@/assets/img/edit.png"
                      @click.stop="editdRow(scope.row)"
                      alt=""
                    />
                  </el-tooltip>
                  <el-tooltip content="不可编辑" placement="top" v-else>
                    <img
                      class="iconImg pointer"
                      src="@/assets/img/grey-edit.png"
                      @click.stop=""
                      alt=""
                    />
                  </el-tooltip>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
        </PocTable>
      </PocCardTable>
    </div>
    <edit-or-msg v-if="manageShow" :jumpParams="jumpParams" @goback="goback" />
    <assignModal
      v-if="assignModalFlag"
      :selecthcIdIds="selecthcIdIds"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import { getDepartmentList } from "@/api/intern";

const { Vuex } = $PCommon
const { mapGetters } = Vuex

import editOrMsg from './editOrMsg.vue'
import assignModal from './assignModal.vue'
import PocSlider from '@/components/poc-slider/poc-slider'
export default {
  name: 'userManage',
  mixins: [$PCommon.TableMixin],
  components: {
    editOrMsg,
    assignModal,
    PocSlider
  },

  data() {
    return {
      maxHeight: '',
      queryLoading: false,
      backLoading: false,
      assignModalFlag: false,
      tableData: [],
      ifSureFlag: true,
      upFlag: false,
      tabLabel: {
        1: 'ALL',
        2: '待处理',
        3: '已关闭',
        4: '已提交'
      },
      typeData: {
        1: 'ALL',
        2: 'ToBeProcessed',
        3: 'closed',
        4: 'submittedBP'
      },
      chooseTab: 1,

      // checked: '',
      columnConfig: [
        {
          key: 'hcNumber',
          name: 'HC number',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'candidateName',
          name: 'Candidate Name',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'estimatedStartDate',
          name: 'Eastimate onboarding Time',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 220 // 默认宽度，像素
        },
        {
          key: 'offerStatus',
          name: 'Offer Status',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 120 // 默认宽度，像素
        },
        {
          key: 'jobTitle',
          name: 'Job Title',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 100 // 默认宽度，像素
        },
        {
          key: 'assigmentProjectLevel2Name',
          name: 'Assigment/project (Level2)',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 200 // 默认宽度，像素
        },
        {
          key: 'descriptionLevel3Name',
          name: 'Description (Level3)',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 200 // 默认宽度，像素
        },
        {
          key: 'client',
          name: 'Client',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 80 // 默认宽度，像素
        },
        {
          key: 'businessUnitName',
          name: 'Business unit',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'department',
          name: 'Department',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'inHandName',
          name: 'In Hand',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 120 // 默认宽度，像素
        },
        {
          key: 'rafApprovedDate',
          name: 'RAF Approved Date',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 180 // 默认宽度，像素
        },
        {
          key: 'creationUserName',
          name: 'Creation User',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 120 // 默认宽度，像素
        },
        {
          key: 'chinaFinanceApproved',
          name: 'China Finance Approved',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 180 // 默认宽度，像素
        },

        {
          key: 'hcStatus',
          name: 'HC Status',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 100 // 默认宽度，像素
        }
      ],
      dateRange: '',
      dateRange2: '',
      formInline: {
        requestId: '',
        jobTitle: '',
        assigmentProjectLevel2: '',
        descriptionLevel3: '',
        departmentId: '',
        businessUnitId: '',
        client: '',
        hcNumber: '',
        titleEn: '',
        inHand: '',
        budgetMonthlySalary: '',
        replacement: '',
        leaverName: '',
        candidateName: '',
        createUser: '',
        createDateStart: '',
        createDateEnd: '',
        rafApprovedDateEnd: '',
        rafApprovedDateStart: '',
        type: 'All'
      },
      handDataList: [], //inhandList
      descriptionLevel3List: [],
      assigmentProjectLevel2List: [],
      businessUnitIdList: [],
      departmentIdList: [], //
      // clientDataList:[],//clientList
      // entityHfmCodeList:[],//entityHfmCodeList
      // businessUnitNameList:[],//businessUnitNameList
      selecthcIdIds: '', // 选中数组id集合字符串
      selectofferProposalIds: '', // 选中数组id集合字符串
      cannotSubmitNameArr: [], //不能提交的候选人名字
      statusList: [
        { id: '0', name: '启用' },
        { id: '1', name: '停用' }
      ],
      props: { multiple: true },
      manageShow: false,
      jumpParams: {},
      selectedItems: [],

      statusDisableFlag: false,
      OfferStatusList: []
    }
  },
  computed: {
    columnOptions() {
      return this.columnConfig.filter((item) => item.visible)
    }
  },

  created() {
    let h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    this.maxHeight = h - 420
    this.filterQuery()
    this.getAssigmentProjectLevel2()
    this.getDescriptionLevel3()
    this.getInHandData()
    // this.getClientData();
    // this.getEntityHfmCode();
    this.getDepartmentIdList()
    this.getDepartmentIdList1()
    this.getOfferStatus();
  },
  methods: {
    async getOfferStatus() {
      const {data, code} = await this.$Api.induction.getOfferStatusList()
      console.log('res', data)
      if (code == 200) {
        this.OfferStatusList = data || [];
      }
    },

    onFilter(value) {
      this.formInline.rafApprovedDateStart = value ? value[0] : ''
      this.formInline.rafApprovedDateEnd = value ? value[1] : ''
    },
    onFilter2(value) {
      this.formInline.createDateStart = value ? value[0] : ''
      this.formInline.createDateEnd = value ? value[1] : ''
    },
    filterQuery() {
      this.pageConfig.pageNum = 1
      this.queryApi()
    },
    // 获取基础数据维护的列表
    async queryApi() {
      this.queryLoading = true
      const { data, code } = await this.$Api.induction.getDataList({
        ...this.formInline,
        ...this.pageConfig
      })
      if (code === 200) {
        this.tableData = data.list || []
        this.pageConfig.total = data.total
        this.$forceUpdate()
      }
      this.queryLoading = false
    },
    refresh() {
      ;(this.formInline = {
        requestId: '',
        jobTitle: '',
        assigmentProjectLevel2: '',
        descriptionLevel3: '',
        departmentId: '',
        businessUnitId: '',
        client: '',
        titleEn: '',
        inHand: '',
        budgetMonthlySalary: '',
        replacement: '',
        leaverName: '',
        candidateName: '',
        createUser: '',
        createDateStart: '',
        createDateEnd: '',
        rafApprovedDateEnd: '',
        rafApprovedDateStart: '',
        type: 'All'
      }),
      this.dateRange2 = null;
      this.dateRange = null;
        this.filterQuery()
    },
    async getInHandData() {
      const { code, data } = await this.$Api.induction.getHandList({})
      if (code === 200) {
        this.handDataList = data
      }
    },
    async getDepartmentIdList() {
      const { code, data } =
        await this.$Api.induction.getdepartmentAndBusinessList({})
      if (code === 200) {
        this.businessUnitIdList = data
      }
    },
    async getDepartmentIdList1() {
      const { code, data } =
        await getDepartmentList({})
      if (code === 200) {
        this.departmentIdList = data
      }
    },
    // selectAll(e) {
    //   if (e == true) {
    //     //全部选中
    //     this.seletAllFlag = true
    //     this.$refs.multipleTable.toggleAllSelection()
    //   } else {
    //     // 全部取消
    //     this.$refs.multipleTable.clearSelection()
    //   }
    // },
    changeBusinessUnitId(e) {
      this.departmentIdList = []
      this.formInline.departmentId = '' //主数据改动，右边必须清空跟着改动
      // this.businessUnitIdList.map((item) => {
      //   if (item.businessUnitId == e) {
      //     console.log('99999', e, item.list)
      //     this.departmentIdList = item.list
      //   }
      // })
    },
    async getAssigmentProjectLevel2() {
      const { code, data } =
        await this.$Api.induction.getAssigmentProjectLevel2({})
      if (code === 200) {
        this.assigmentProjectLevel2List = data
      }
    },
    async getDescriptionLevel3() {
      const { code, data } = await this.$Api.induction.getDescriptionLevel3({})
      if (code === 200) {
        this.descriptionLevel3List = data
      }
    },

    // async getClientData() {
    //   const { code, data } = await this.$Api.induction.getClientData({
    //   })
    //   if (code === 200) {
    //     this.clientDataList=data;
    //   }
    // },
    // async getEntityHfmCode() {
    //   const { code, data } = await this.$Api.induction.getEntityHfmCode({
    //   })
    //   if (code === 200) {
    //     this.entityHfmCodeList=data;
    //   }
    // },
    //  async getBusinessUnitName() {
    //   const { code, data } = await this.$Api.induction.getBusinessUnitName({
    //   })
    //   if (code === 200) {
    //     this.businessUnitNameList=data;
    //   }
    // },

    upRow() {
      this.upFlag = !this.upFlag
    },
    goback(val = '') {
      this.manageShow = false
      if (val && val !== 'goBack') {
        this.$refs.multipleTable.clearSelection()
        this.filterQuery()
      }
    },
    changeTab(val) {
      // alert(val)
      this.chooseTab = val
      if (this.chooseTab != '1') {
        this.statusDisableFlag = true
        this.formInline.offerStatus = ''
      } else {
        this.statusDisableFlag = false
      }
      // all-all;待处理:;已提交Bp:;已关闭：
      this.formInline.type = this.typeData[val]
      this.filterQuery()
    },
    handleSelectionChange(val) {
      // 选的第一个
      if (val.length) {
        this.selectedItems = val
        this.selecthcIdIds = this.selectedItems.map((v) => v.hcId).join()
        this.selectofferProposalIds = this.selectedItems
          .map((v) => v.offerProposalId)
          .join()
      } else {
        this.selecthcIdIds = ''
        this.selectofferProposalIds = ''
      }
    },
    goDetail(row) {
      this.manageShow = true
      this.jumpParams = {
        jumpFlag: 'goDetail',
        hcId: row.hcId,
        offerProposalId: row.offerProposalId,
        offerStatusNumber: row.offerStatusNumber
      }
      this.$refs.multipleTable.clearSelection();
      this.selectedItems = [];
    },
    // 编辑信息
    editdRow(row) {
      this.manageShow = true
      this.jumpParams = {
        jumpFlag: 'edit',
        hcId: row.hcId,
        offerProposalId: row.offerProposalId,
        offerStatusNumber: row.offerStatusNumber
      }
    },
    // 分配
    assign() {
      if (!this.selecthcIdIds) {
        this.$message({
          type: 'success',
          message: '请选择数据'
        })
        return
      }
      this.assignModalFlag = true
      console.log('父组件打印', this.selecthcIdIds)
    },
    closeModal() {
      this.$refs.multipleTable.clearSelection()
      this.selecthcIdIds = '' //清空选中数据
      this.filterQuery()
      this.assignModalFlag = false
    },
    // 提交功能
    async submitBP() {
      if (!this.selecthcIdIds) {
        this.$message({
          type: 'success',
          message: '请选择数据'
        })
        return
      }
      this.selectedItems.map((d) => {
        if (
          (d.offerStatusNumber != '8' &&
            d.offerStatusNumber != '9' &&
            d.offerStatusNumber != '0' &&
            d.offerStatusNumber != '2') ||
          !d.offerProposalId
        ) {
          this.cannotSubmitNameArr.push(d.candidateName)
        }
      })
      if (this.cannotSubmitNameArr.length) {
        this.$message({
          type: 'warning',
          message: this.cannotSubmitNameArr.join(',') + '不可提交'
        })
        this.cannotSubmitNameArr = []
        return
      }
      let msg = '提交成功'
      const { code, data } = await this.$Api.induction.submitBP({
        ids: this.selectofferProposalIds
      })
      if (code === 200) {
        this.$message({
          type: 'success',
          message: msg
        })
        this.cannotSubmitNameArr = [] //清空不可提交的数据
        this.$refs.multipleTable.clearSelection()
        this.selecthcIdIds = '' //清空选中数据

        await this.queryApi() // 刷新下界面
      }
    },
    // 收回功能
    async backBP() {
      if (!this.selecthcIdIds) {
        this.$message({
          type: 'success',
          message: '请选择数据'
        })
        return
      }
      let msg = '收回成功'
      const { code, data } = await this.$Api.induction.back({
        ids: this.selecthcIdIds
      })
      if (code === 200) {
        this.$message({
          type: 'success',
          message: msg
        })
        this.selecthcIdIds = ''
        this.$refs.multipleTable.clearSelection()
        await this.queryApi() // 刷新下界面
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {})
    window.removeEventListener('keyup', () => {})
  },
  mounted() {
    window.addEventListener('keyup', (e) => {
      if ((e.code == 'Enter' || e.target == 13) && e.target.id == 'inputid') {
        console.log(e)
        this.filterQuery()
      }
    })
    window.addEventListener('resize', () => {
      const h =
        document.getElementsByTagName('body')[0].clientHeight - 340 + 'px'
      this.maxHeight = h
    })
  }
}
</script>
<style lang="scss" scoped>
.opeartionBox {
  padding: 4px 0 16px 0px;
  box-sizing: border-box;
  // border: 1px solid red;
}
.buttonBox {
  padding: 0px 0 12px 0px;
  box-sizing: border-box;
}
</style>
<style lang="scss">
.elformInputBox {
  .el-input,
  .el-select {
    width: 166px;
  }
}
.induction-list-page .poc-slider .poc-slider-btns .el-button {
  margin-right: 0 !important;
}
</style>
