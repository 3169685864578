<template>
  <div class="">
    <div class="scrollContainer">
      <el-form
        class="filter-form rpm-query-from el-form"
        :rules="rules"
        :key="formKey"
        :model="selectEditMsg"
        :inline="true"
        id="offerProposalForm"
        :label-position="'top'"
        ref="offerProposalForm"
      >
        <div class="inductionTitleLab inductionTitleMsg">基本信息</div>
        <div class="flexBetw">
          <el-form-item label="Candidate Name" prop="candidateName">
            <el-input
              placeholder="请输入Candidate Name"
              v-model="selectEditMsg.candidateName"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="Candidate English Name"
            prop="candidateEnglishName"
          >
            <el-input
              placeholder="请输入candidate English Name"
              v-model="selectEditMsg.candidateEnglishName"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="Candidate Phone" prop="candidatePhone">
            <el-input
              placeholder="请输入candidate Phone"
              v-model="selectEditMsg.candidatePhone"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="Candidate Email" prop="candidateEmail">
            <el-input
              placeholder="请输入Candidate Email"
              v-model="selectEditMsg.candidateEmail"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="Country" prop="country">
            <el-select
              clearable
              v-model="selectEditMsg.country"
              filterable
              placeholder="请选择"
              class="industry-selector"
              @change="selectCountry"
            >
              <el-option
                v-for="item in countryList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="ExpatOrLocalName" prop="expatLocal">
            <el-select
              clearable
              v-model="selectEditMsg.expatLocal"
              filterable
              @change="expatLocalChange"
              placeholder="请选择"
              class="industry-selector"
            >
              <el-option
                v-for="item in expatLocalList"
                :key="item.label"
                :label="item.value"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Proposed Onboard Date"
            prop="proposedOnboardDate"
          >
            <el-date-picker
              v-model="selectEditMsg.proposedOnboardDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择时间"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="HRBP">
            <el-select
              clearable
              disabled
              v-model="selectEditMsg.hrbp"
              filterable
              placeholder="请选择"
              class="industry-selector"
            >
              <el-option
                v-for="item in hrbpList"
                :key="item.label"
                :label="item.value"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Team">
            <el-input placeholder="请输入Team" v-model="selectEditMsg.team">
            </el-input>
          </el-form-item>
          <el-form-item label="Job Grading" prop="jobGrading">
            <el-select
              clearable
              v-model="selectEditMsg.jobGrading"
              filterable
              placeholder="请选择"
              class="industry-selector"
              @change="selectJobGarding"
            >
              <el-option
                v-for="item in jobGradingList"
                :key="item.value + '1212' + Math.random()"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Working Location" prop="workingLocation">
            <el-select
              clearable
              v-model="selectEditMsg.workingLocation"
              filterable
              placeholder="请选择"
              class="industry-selector"
              @change="locationChange"
            >
              <el-option
                v-for="item in workingLocationList"
                :key="item.workLocation"
                :label="item.workLocation"
                :value="item.workLocation"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Office Location">
            <el-select
              clearable
              v-model="selectEditMsg.officeLocation"
              filterable
              placeholder="请选择"
              class="industry-selector"
            >
              <el-option
                v-for="item in officeList"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="ID Type" prop="idType">
            <el-select
              clearable
              v-model="selectEditMsg.idType"
              filterable
              placeholder="请选择"
              @change="idTypeChange"
              class="industry-selector"
            >
              <el-option
                v-for="item in idTypeList"
                :key="item.label"
                :label="item.value"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="ID Number" prop="idNumber">
            <el-input
              placeholder="请输入ID Number"
              @blur="idNumberChange"
              v-model="selectEditMsg.idNumber"
            >
            </el-input
            >       
          </el-form-item>
          <el-form-item label="Rejoin Employee">
            <el-select
              clearable
              v-model="selectEditMsg.rejoinEmployee"
              @change="rejoinEmployeeChange"
              filterable
              placeholder="请选择"
              class="industry-selector"
            >
              <el-option
                v-for="item in rejoinEmployeeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Fresh/Non-Fresh Graduate" prop="freshGraduate">
            <el-select
              clearable
              v-model="selectEditMsg.freshGraduate"
              filterable
              placeholder="请选择"
              class="industry-selector"
            >
              <el-option
                v-for="item in GraduateList"
                :key="item.label"
                :label="item.value"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Lion ID of LM" prop="lineManagerLiid">
            <el-input
              v-model="selectEditMsg.lineManagerLiid"
              suffix-icon="el-icon-search"
              @click.native="selectPerson('induction')"
              placeholder="请选择"
              readonly
            />
          </el-form-item>
          <el-form-item label="Name of LM" prop="lineManagerName">
            <el-input
              v-model="selectEditMsg.lineManagerName"
              disabled
              placeholder="请选择"
            />
          </el-form-item>
          <!-- <el-form-item label="LM Email" prop="lineManagerEmail">
            <el-input
              v-model="selectEditMsg.lineManagerEmail"
              disabled
              suffix-icon="el-icon-search"
              placeholder="请选择"
            />
          </el-form-item> -->
          <el-form-item label="Recruitment Channel" prop="recruitmentChannel">
            <el-select
              clearable
              v-model="selectEditMsg.recruitmentChannel"
              filterable
              placeholder="请选择"
              class="industry-selector"
            >
              <el-option
                v-for="item in recruitmentChannelList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Recruitment Channel Remark">
            <el-input
              placeholder="请输入Recruitment Channel Remark"
              v-model="selectEditMsg.recruitmentChannelRemark"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="Candidate's Previous Company Category" prop="candidatePreviousCompanyCategoryId">
            <el-select
              clearable
              v-model="selectEditMsg.candidatePreviousCompanyCategoryId"
              filterable
              placeholder="请选择"
              class="industry-selector"
            >
              <el-option
                v-for="item in companyCategorylList"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Candidate's Previous Company">
            <el-input
              placeholder="请输入Candidate's Previous Company"
              v-model="selectEditMsg.candidatePreviousCompany"
            >
            </el-input>
          </el-form-item>



        </div>

        <div class="inductionTitleLab inductionTitleMsg2">薪资和年假</div>
        <div class="flexBetw" v-if="selectEditMsg.expatLocal == 'Expat'">
          <el-form-item
            label="Annual Package(Expat)"
            prop="annualPackage"
          >
            <el-input-number
              v-model="selectEditMsg.annualPackage"
            ></el-input-number>
          </el-form-item>
        </div>
        <div class="flexBetw">
          <el-form-item
            :label="`Offered Monthly Salary (during ${probation})`"
            prop="offeredMonthlySalaryDuringProbtion"
            v-if="isShowReginEmployeeProbation"
          >
            <el-input-number
              v-model="selectEditMsg.offeredMonthlySalaryDuringProbtion"
            ></el-input-number>
          </el-form-item>
          <el-form-item
            :label="`Offered Monthly Salary (after ${probation})`"
            prop="offeredMonthlySalaryAfterProbtion"
            v-if="isShowReginEmployeeProbation"
          >
            <el-input-number
              v-model="selectEditMsg.offeredMonthlySalaryAfterProbtion"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="Annual Leave Days" prop="annualLeaveDays">
            <el-input-number
              v-model="selectEditMsg.annualLeaveDays"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="Work Schedules" prop="workSchedules">
            <el-select
              clearable
              v-model="selectEditMsg.workSchedules"
              filterable
              placeholder="请选择"
              class="industry-selector"
            >
              <el-option
                v-for="item in workSchedulesList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Offered Monthly Salary(during Assessment)"
            prop="offeredMonthlySalaryDuringAssessment"
            v-if="isShowReginEmployeeAssessment"
          >
            <el-input
              placeholder="请输入"
              oninput="value=value.replace(/[^0-9.]/g,'')"
              v-model="selectEditMsg.offeredMonthlySalaryDuringAssessment"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="Offered Monthly Salary(after Assessment)"
            prop="offeredMonthlySalaryAfterAssessment"
            v-if="isShowReginEmployeeAssessment"
          >
            <el-input
              placeholder="请输入"
              oninput="value=value.replace(/[^0-9.]/g,'')"
              v-model="selectEditMsg.offeredMonthlySalaryAfterAssessment"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="Offered 13th Salary" prop="thirteenSalary">
            <el-select
              clearable
              v-model="selectEditMsg.thirteenSalary"
              filterable
              placeholder="请选择"
              class="industry-selector"
            >
              <el-option
                v-for="item in offered13thSalaryList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Monthly Total Allowance"
            prop="monthlyTotalAllowance"
          >
            <el-input-number
              v-model="selectEditMsg.monthlyTotalAllowance"
              :disabled="ExpatOrLocalName == 'Local'"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="Bonus Eligible (Y/N)" prop="">
            <el-select
              clearable
              v-model="selectEditMsg.bonusEligible"
              filterable
              @change="bonusChange"
              placeholder="请选择"
              class="industry-selector"
            >
              <el-option
                v-for="item in bonusEligibleList"
                :key="item.label"
                :label="item.value"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Currency" prop="">
            <el-input
              placeholder="请输入Currency"
              v-model="selectEditMsg.currency"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="Discretionary Bonus Plan"
            prop="discretionaryBonusPlan"
          >
            <el-input
              placeholder="请输入Discretionary Bonus Plan"
              v-model="selectEditMsg.discretionaryBonusPlan"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="Discretionary Target Bonus %"
            prop="discretionaryTargetBonus"
          >
            <el-input
              placeholder="请输入Discretionary Target Bonus %"
              v-model="selectEditMsg.discretionaryTargetBonus"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="Pay Frequency" prop="offered13thSalary">
            <el-input
              placeholder="请输入Pay Frequency"
              v-model="selectEditMsg.payFrequency"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="Special Pool Bonus Plan" prop="">
            <el-select
              clearable
              v-model="selectEditMsg.specialPoolBonusPlan"
              filterable
              placeholder="请选择"
              class="industry-selector"
            >
              <el-option
                v-for="item in specialPoolBonusPlanList"
                :key="item.label"
                :label="item.value"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="inductionTitleLab inductionTitleMsg2">合同信息</div>
        <div class="flexBetw">
          <el-form-item label="Contract Period" prop="contractPeriod">
            <el-select
              clearable
              v-model="selectEditMsg.contractPeriod"
              filterable
              placeholder="请选择"
              class="industry-selector"
              @change="selectContractPeriod"
            >
              <el-option
                v-for="item in contractPeriodList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Notice Period" prop="noticePeriod">
            <el-select
              clearable
              v-model="selectEditMsg.noticePeriod"
              filterable
              placeholder="请选择"
              class="industry-selector"
              @change="selChange"
            >
              <el-option
                v-for="item in noticePeriodList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="`${upCase(probation)} Period (Monthly)`"
            prop="probationPeriodMonthly"
            v-if="isShowReginEmployeeProbation"
          >
            <el-input-number
              v-model="selectEditMsg.probationPeriodMonthly"
            ></el-input-number>
          </el-form-item>
          <el-form-item
            :label="`Notice Period (within ${probation})`"
            prop="noticePeriodWithinProbation"
            v-if="isShowReginEmployeeProbation"
          >
            <el-select
              clearable
              v-model="selectEditMsg.noticePeriodWithinProbation"
              filterable
              placeholder="请选择"
              class="industry-selector"
            >
              <el-option
                v-for="item in noticePeriodWithAssesmentList"
                :key="item.label"
                :label="item.value"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="`Title CN(during ${probation})`"
            prop="titleCnDuringProbation"
            v-if="isShowReginEmployeeProbation"
          >
            <el-input
              :placeholder="`请输入Title CN(during ${probation})`"
              v-model="selectEditMsg.titleCnDuringProbation"
              @change="titleCNChange"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            :label="`Title EN(during ${probation})`"
            prop="titleEnDuringProbation"
            v-if="isShowReginEmployeeProbation"
          >
            <el-input
              :placeholder="`请输入Title EN(during ${probation})`"
              v-model="selectEditMsg.titleEnDuringProbation"
              @change="titleENChange"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            :label="`Title CN(after ${probation})`"
            prop="titleCnAfterProbation"
            v-if="isShowReginEmployeeProbation"
          >
            <el-input
              :placeholder="`请输入Title CN(after ${probation})`"
              v-model="selectEditMsg.titleCnAfterProbation"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            :label="`Title EN(after ${probation})`"
            prop="titleEnAfterProbation"
            v-if="isShowReginEmployeeProbation"
          >
            <el-input
              :placeholder="`请输入Title EN(after ${probation})`"
              v-model="selectEditMsg.titleEnAfterProbation"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="Assessment Period (Monthly)"
            prop="assessmentPeriod"
            v-if="isShowReginEmployeeAssessment"
          >
            <el-input-number
              v-model="selectEditMsg.assessmentPeriod"
            ></el-input-number>
          </el-form-item>
          <el-form-item
            label="Notice Period(within assessment)"
            prop="noticePeriodWithAssessment"
            v-if="isShowReginEmployeeAssessment"
          >
            <el-select
              clearable
              v-model="selectEditMsg.noticePeriodWithAssessment"
              filterable
              placeholder="请选择"
              class="industry-selector"
            >
              <el-option
                v-for="item in noticePeriodWithAssesmentList"
                :key="item.label"
                :label="item.value"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Title CN(during assessment)"
            prop="titleCnDuringAssessment"
            v-if="isShowReginEmployeeAssessment"
          >
            <el-input
              placeholder="请输入Title CN(during assessment)"
              v-model="selectEditMsg.titleCnDuringAssessment"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="Title EN(during assessment)"
            prop="titleEnDuringAssessment"
            v-if="isShowReginEmployeeAssessment"
          >
            <el-input
              placeholder="请输入Title EN(during assessment)"
              v-model="selectEditMsg.titleEnDuringAssessment"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="Title CN(after assessment)"
            prop="titleCnAfterAssessment"
            v-if="isShowReginEmployeeAssessment"
          >
            <el-input
              placeholder="请输入Title CN(after assessment)"
              v-model="selectEditMsg.titleCnAfterAssessment"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="Title EN(after assessment)"
            prop="titleEnAfterAssessment"
            v-if="isShowReginEmployeeAssessment"
          >
            <el-input
              placeholder="请输入Title EN(after assessment)"
              v-model="selectEditMsg.titleEnAfterAssessment"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="Contractual Bonus Plan" prop="contractualBonusPlan">
            <el-select
              clearable
              v-model="selectEditMsg.contractualBonusPlan"
              filterable
              placeholder="请选择"
              class="industry-selector"
            >
              <el-option
                v-for="item in contractualBonusPlanList"
                :key="item.label"
                :label="item.value"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Contractual Target Bonus %"
            prop="offered13thSalary"
          >
            <el-input
              placeholder="请输入Contractual Target Bonus %"
              v-model="selectEditMsg.contractualTargetBonus"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="Regular/ Temporary">
            <el-select
              clearable
              v-model="selectEditMsg.regularTemporary"
              filterable
              placeholder="请选择"
              class="industry-selector"
            >
              <el-option
                v-for="item in regularTemporaryList"
                :key="item.label"
                :label="item.value"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="inductionTitleLab inductionTitleMsg2">其他</div>
        <div class="flexBetw">
          <el-form-item label="Challenge Group">
            <el-select
              clearable
              v-model="selectEditMsg.challengeGroup"
              filterable
              placeholder="请选择"
              class="industry-selector"
            >
              <el-option
                v-for="item in ChallengeGroupList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Employee Type">
            <el-select
              clearable
              v-model="selectEditMsg.employeeType"
              filterable
              placeholder="请选择"
              class="industry-selector"
            >
              <el-option
                v-for="item in EmployeeTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Original Start Date">
            <el-date-picker
              style="width: 100%"
              v-model="selectEditMsg.originalStartDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择统计月份"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="Relocation">
            <el-select
              clearable
              v-model="selectEditMsg.relocation"
              filterable
              placeholder="请选择"
              class="industry-selector"
              @change="relocationChange"
            >
              <el-option
                v-for="item in relocationList"
                :key="item.label"
                :label="item.value"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Home City"
            :prop="isRelocationShow ? 'homeCity' : ''"
          >
            <el-input
              placeholder="请输入Home City"
              v-model="selectEditMsg.homeCity"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="Maximum Moving Cost"
            :prop="isRelocationShow ? 'maximumMovingCost' : ''"
          >
            <el-input
              placeholder="请输入Maximum Moving Cost"
              v-model="selectEditMsg.maximumMovingCost"
              onkeyup="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="Maximum Temporary Accommodation cost"
            :prop="isRelocationShow ? 'maximumTemporaryAccommodationCost' : ''"
          >
            <el-input
              placeholder="请输入Maximum Temporary Accommodation cost"
              v-model="selectEditMsg.maximumTemporaryAccommodationCost"
              onkeyup="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="Home Trip">
            <el-select
              clearable
              v-model="selectEditMsg.homeTrip"
              filterable
              placeholder="请选择"
              class="industry-selector"
              @change="changeHomeTrip"
            >
              <el-option
                v-for="item in homeTripList"
                :key="item.label"
                :label="item.value"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="With Spouse"
            :prop="isHomeTripShow ? 'withSpouse' : ''"
          >
            <el-select
              clearable
              v-model="selectEditMsg.withSpouse"
              filterable
              placeholder="请选择"
              class="industry-selector"
            >
              <el-option
                v-for="item in withSpouseList"
                :key="item.label"
                :label="item.value"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Additional Commercial Insurance"
            prop="additionalCommercialInsurance"
          >
            <el-select
              clearable
              v-model="selectEditMsg.additionalCommercialInsurance"
              filterable
              placeholder="请选择"
              class="industry-selector"
              @change="additionalCommercialInsuranceChange"
            >
              <el-option
                v-for="item in additionalCommercialInsuranceList"
                :key="item.label"
                :label="item.value"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="For employee only"
            :prop="ifEmployeeOnlyFlag ? 'forEmployeeOnly' : ''"
          >
            <el-select
              clearable
              v-model="selectEditMsg.forEmployeeOnly"
              filterable
              placeholder="请选择"
              class="industry-selector"
              @change="employeeOnlyChange"
            >
              <el-option
                v-for="item in employeeOnlyList"
                :key="item.label"
                :label="item.value"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Insurance Applicant" prop="offered13thSalary">
            <el-input
              placeholder="请输入Insurance Applicant"
              v-model="selectEditMsg.insuranceApplicant"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="Insurance Relationship"
            :prop="isRelationshipFlag ? 'insuranceRelationship' : ''"
          >
            <el-input
              placeholder="请输入Insurance Relationship"
              v-model="selectEditMsg.insuranceRelationship"
            >
            </el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <!-- //按钮区域，只有编辑时生效 -->
    <!-- <div class="flexRow buttonBottom">
      <el-button type="primary" @click="save" :disabled="ifEditFlag">保存</el-button>
      <el-button type="primary" @click="cancle">取消</el-button>
    </div> -->
    <personnelSelectionDialog
      ref="personnelSelectionDialogRef"
      @onConfirm="onConfirm"
      :hfmCode="this.selectEditMsg.hfmCode"
    />
  </div>
</template>

<script>
import { check, typeRange, upCase } from "@/utils/util";

const { Vuex } = $PCommon
import { checkIdCard, rules } from '@/views/inductionManage/rules'
import personnelSelectionDialog from '@/components/PersonnelSelectionDialog/PersonnelSelectionDialog'
export default {
  name: 'userManage',
  mixins: [$PCommon.TableMixin],
  components: {
    personnelSelectionDialog
  },
  props: {
    jumpParams: {
      type: Object
    }
  },

  data() {
    return {
      editStatus:false,//销毁时是否可编辑
      ifEditFlag: false,
      queryLoading: false,
      ExpatOrLocalName: 'Local',
      selectEditMsg: {
        candidatePreviousCompanyCategoryId: '',
        candidatePreviousCompany: '',
        candidateName: '',
        candidateEnglishName: '',
        candidatePhone: '',
        candidateEmail: '',
        country: '',
        expatLocal: '',
        proposedOnboardDate: '',
        hrbp: '',
        team: '',
        jobGrading: '',
        workingLocation: '',
        officeLocation: '',
        rejoinEmployee: '',
        recruitmentChannel: '',
        recruitmentChannelRemark: '',
        offeredMonthlySalaryDuringProbtion: '',
        offeredMonthlySalaryAfterProbtion: '',
        annualLeaveDays: '',
        workSchedules: '',
        offered13thSalary: '',
        contractPeriod: '',
        noticePeriod: '',
        probationPeriodMonthly: '',
        noticePeriodWithinProbation: '',
        challengeGroup: '',
        employeeType: '',
        benefitsEligibilityStartDate: '',
        originalStartDate: '',
        seniorityStartDate: '',
        serviceDate: '',
        hiringId: '',
        monthlyTotalAllowance: '',
        // titleCn:'',
        idType: '',
        idNumber: '',

        annualPackage: '',
        offeredMonthlySalaryDuringAssessment: '',
        offeredMonthlySalaryAfterAssessment: '',
        bonusEligible: 'YES',
        currency: 'CNY',
        discretionaryBonusPlan: 'Discretionary Other',
        discretionaryTargetBonus: '',
        payFrequency: 'Monthly',
        specialPoolBonusPlan: 'No Selection',

        titleCNDduringpProbation: '',
        titleCNafterProbation: '',
        titleENduringProbation: '',
        titleEENafterProbation: '',
        assessmentPeriod: '',
        noticePeriodWithAssessment: 'Months',
        titleCnDuringAssessment: '',
        titleCnAfterAssessment: '',
        titleEnDuringAssessment: '',
        titleEnAfterAssessment: '',
        contractualBonusPlan: '',
        contractualTargetBonus: '',
        regularTemporary: '',
        relocation: '',
        homeCity: '',
        maximumMovingCost: '',
        maximumTemporaryAccommodationCost: '',
        homeTrip: '',
        withSpouse: '',
        additionalCommercialInsurance: '',
        insuranceRelationship: '',
        insuranceApplicant: '',
        freshGraduate: '',
        id: '',
        forEmployeeOnly: '',
        lineManagerLiid: '',
        lineManagerEmail: '',
        lineManagerName: '',
        titleEnAfterProbation: '',
        titleCnAfterProbation: ''
      }, // 选中修改的一系列用户信息

      isMustShow: false, //默认不必填
      ifEmployeeOnlyFlag: false,
      isRelationshipFlag: false,
      isRelocationShow: false,
      isHomeTripShow: false,
      jobGradingList: [],
      countryList: [],
      noticePeriodList: [],
      noticePeriodWithinProbationList: [],
      contractPeriodList: [],
      offered13thSalary: [],
      recruitmentChannelList: [],
      companyCategorylList: [],
      officeList: [],
      formKey: Math.random(),
      rejoinEmployeeList: [
        { value: '是', label: '是' },
        { value: '否', label: '否' }
      ],
      EmployeeTypeList: [],
      ChallengeGroupList: [
        { value: '是', label: '是' },
        { value: '否', label: '否' }
      ],
      idTypeList: [
        { value: 'ID Card', label: 'ID Card' },
        { value: 'Passport', label: 'Passport' },
        { value: 'Other', label: 'Other' }
      ],
      GraduateList: [
        { value: 'Fresh Graduate', label: 'Fresh Graduate' },
        { value: 'Non-Fresh Graduate', label: 'Non-Fresh Graduate' }
      ],
      workSchedulesList: [],
      workingLocationList: [],
      offered13thSalaryList: [
        { value: true, label: '是' },
        { value: false, label: '否' }
      ],
      expatLocalList: [],
      hrbpList: [],
      // 新加
      contractualBonusPlanList: [
        { value: 'No Selection', label: 'No Selection' },
        { value: 'individual Contractual', label: 'individual Contractual' },
        { value: 'Sapient Fixed Pay', label: 'Sapient Fixed Pay' }
      ],
      noticePeriodWithAssesmentList: [
        { value: '3', label: '3' },
        { value: '15', label: '15' },
        { value: '30', label: '30' }
      ],
      regularTemporaryList: [
        { value: 'Regular', label: 'Regular' },
        { value: 'Temporary', label: 'Temporary' }
      ],
      bonusEligibleList: [
        { value: 'YES', label: 'YES' },
        { value: 'No', label: 'No' }
      ],
      specialPoolBonusPlanList: [
        { value: 'No Selection', label: 'No Selection' },
        { value: 'CNC', label: 'CNC' },
        { value: 'KEKST', label: 'KEKST' },
        { value: 'PLR-DPTZ', label: 'PLR-DPTZ' },
        { value: 'PLR-LBTM', label: 'PLR-LBTM' },
        { value: 'PLR-Talent Marcel', label: 'PLR-Talent Marcel' },
        { value: 'Pixel Park', label: 'Pixel Park' },
        { value: 'Publicis Live', label: 'Publicis Live' },
        { value: 'Toyota', label: 'Toyota' }
      ],
      relocationList: [
        { value: 'YES', label: 'YES' },
        { value: 'No', label: 'No' }
      ],
      homeTripList: [
        { value: 'YES', label: 'YES' },
        { value: 'No', label: 'No' }
      ],
      withSpouseList: [
        { value: 'YES', label: 'YES' },
        { value: 'No', label: 'No' }
      ],
      additionalCommercialInsuranceList: [
        { value: 'YES', label: 'YES' },
        { value: 'No', label: 'No' }
      ],
      employeeOnlyList: [
        { value: 'YES', label: 'YES' },
        { value: 'No', label: 'No' }
      ],

      //新加字段
      insuranceApplicant: [],
      rules: rules,
      props: { multiple: true },
      isShowReginEmployeeAssessment: false,
      isShowReginEmployeeProbation: false,
      isCreated:false
    }
  },
  computed: {
    probation(){
      if(this.selectEditMsg.rejoinEmployee === '是'){
        return 'assessment'
      }
      return 'probation'
    }
  },
  watch:{
    selectEditMsg:{
      handler(newValue, oldValue) {
        if(this.isCreated){
          console.log('selectEditMsg',oldValue);
          console.log('selectEditMsg',newValue);
          this.$emit('changedData',true)
        }
      },
      deep: true
    }
  },
  async created() {
    console.log('aaaaa-----a1', this.selectEditMsg.noticePeriod)
    this.bonusChange(this.selectEditMsg.bonusEligible)
    await this.getLocation()
    await this.getOfferProposal(
      this.jumpParams.hcId,
      this.jumpParams.offerProposalId
    )
    await this.getHRBP(this.jumpParams.hcId)
    await this.getOfferProposalSelectedData()
    await this.getCountry()
    this.rejoinEmployeeChange()
    this.idTypeChange()
    this.expatLocalChange(this.selectEditMsg.expatLocal)
    this.isCreated = true
  },

  methods: {
    upCase,
    selChange(e) {
      console.log(
        'aaa---',
        e,
        this.selectEditMsg,
        this.selectEditMsg.noticePeriod,
        this.noticePeriodList
      )
      let label = this.noticePeriodList.filter(a=>a.value === e)[0].label
      this.selectEditMsg.noticePeriodName = label
    },
    selectPerson(val) {
      this.dialogStatus = val
      this.$refs.personnelSelectionDialogRef.show({
        position: val,
        radio: true
      })
    },
    idTypeChange() {
      let idType = this.selectEditMsg.idType
      if (idType !== 'ID Card') {
        this.$refs.offerProposalForm.clearValidate('idNumber')
        delete this.rules['idNumber']
      } else {
        this.rules['idNumber'] = checkIdCard()
      }
      this.formKey = Math.random()
    },
    bonusChange(val) {
      if (val && val === 'YES') {
        this.rules['discretionaryTargetBonus'] = [
          { required: true, trigger: 'blur' }
        ]
        this.formKey = Math.random()
      } else {
        this.rules['discretionaryTargetBonus'] = []
        this.formKey = Math.random()
      }
    },
    expatLocalChange(val) {
      if (val && val === 'Expat') {
        this.isRelocationShow = true
        this.isRelationshipFlag = true
        this.formKey = Math.random()
      } else {
        this.isRelocationShow = false
        this.isRelationshipFlag = false
      }
    },
    addRules(val) {
      let required1 = [
        'offeredMonthlySalaryDuringAssessment',
        'offeredMonthlySalaryAfterAssessment',
        'noticePeriodWithAssessment',
        'titleCnDuringAssessment',
        'titleEnDuringAssessment',
        'titleCnAfterAssessment',
        'assessmentPeriod',
        'titleEnAfterAssessment'
      ]
      let required0 = [
        'offeredMonthlySalaryDuringProbtion',
        'offeredMonthlySalaryAfterProbtion',
        'titleCnDuringProbation',
        'titleEnDuringProbation',
        'titleCnAfterProbation',
        'titleEnAfterProbation',
        'noticePeriodWithinProbation',
        'probationPeriodMonthly'
      ]
      let offerProposalForm = this.$refs.offerProposalForm
      if (val) {
        offerProposalForm.clearValidate(required0)
        offerProposalForm.clearValidate(required1)
        this.isShowReginEmployeeProbation = false
        this.isShowReginEmployeeAssessment = false
        required0.forEach((item) => {
          delete this.rules[item]
        })
        this.isShowReginEmployeeAssessment = true
        required1.forEach((item) => {
          this.rules[item] = [{ required: true, trigger: 'blur' }]
        })
        this.formKey = Math.random()
      } else {
        offerProposalForm.clearValidate(required0)
        offerProposalForm.clearValidate(required1)
        this.isShowReginEmployeeProbation = false
        this.isShowReginEmployeeAssessment = false
        required1.forEach((item) => {
          delete this.rules[item]
        })
        this.isShowReginEmployeeProbation = true
        required0.forEach((item) => {
          this.rules[item] = [{ required: true, trigger: 'blur' }]
        })
        this.formKey = Math.random()
      }
    },
    rejoinEmployeeChange() {
      let employee = this.selectEditMsg.rejoinEmployee
      if (employee === '是') {
        this.addRules(false)
      } else {
        this.addRules(false)
      }
    },
    idNumberChange() {
      let idNumber = this.selectEditMsg.idNumber
      this.$Api.induction.checkRejoin({ idNumber }).then((res) => {
        if (res && res.code === 200) {
          if (res.data) {
            if (res.data === '0') {
              this.selectEditMsg.rejoinEmployee = '否'
            } else if (res.data === '1') {
              this.selectEditMsg.rejoinEmployee = '是'
            }

            this.rejoinEmployeeChange()
          }
        }
      })
    },
    selectJobGarding(value) {
      console.log(value)
      let findLabel = this.jobGradingList.find((item) => item.value === value)
      if (findLabel) {
        value = findLabel.label
      }
      console.log(value)
      /*
        this.selectEditMsg.annualLeaveDays = '15'
        this.selectEditMsg.noticePeriod = '2'
        this.selectEditMsg.noticePeriodWithinProbation = '15'
      */
      if (value) {
        let params = {
          'hiringId':this.jumpParams.hcId,
          'jobGrading':value
        }
        this.$Api.induction.getNoticePeriod(params)
          .then(res=>{
            if(res.code===200){
              this.selectEditMsg.noticePeriod = res.data.noticePeroid
              this.selectEditMsg.noticePeriodWithinProbation = res.data.noticePeroidWithinProbation
            }
          })
        this.$Api.induction.getAnnualLeaveDays(params)
          .then(res=>{
            if(res.code === 200){
              this.selectEditMsg.annualLeaveDays = res.data
            }
          })
      }
    },
    selectContractPeriod(value) {
      if (value == 182471) {
        this.selectEditMsg.probationPeriodMonthly = 6
      } else if (value == 182470) {
        this.selectEditMsg.probationPeriodMonthly = 4
      } else if ((value = 182469)) {
        this.selectEditMsg.probationPeriodMonthly = 2
      }
    },
    onConfirm(val) {
      if (val && val.length) {
        console.log('选择', val)
        this.selectEditMsg.lineManagerLiid = val[0].lineManagerLiid
        this.selectEditMsg.lineManagerName = val[0].englishName || val[0].userName;
        this.selectEditMsg.lineManagerEmail = val[0].email
        val[0].lineManagerBaseId !== undefined ? (this.selectEditMsg.lineManagerBaseId = val[0].lineManagerBaseId) : ''
        val[0].lineManagerPerformanceBaseId !== undefined ? (this.selectEditMsg.lineManagerPerformanceBaseId = val[0].lineManagerPerformanceBaseId) : ''
      }
    },
    changeProbationPeriod(e) {
      console.log('eeee', e)

      if (e == 0) {
        //设置不必填
        this.addRules(false)
        // Title CN(during probation)
        // Title CN(after probation)
        // Title EN(during probation)
        // Title EN(after probation)
        // Assessment period
        // Title CN(during Assessment)
        // Title CN(after Assessment)
        // Title EN(during Assessment)
        // Title EN(afterAssessment)
      } else {
        //以下数据需要必填
        this.addRules(true)
      }
    },
    relocationChange(e) {
      if (e == 'YES') {
        this.isRelocationShow = true
      } else {
        this.isRelocationShow = false
      }
    },
    additionalCommercialInsuranceChange(e) {
      if (e == 'YES') {
        this.ifEmployeeOnlyFlag = true
      } else {
        this.ifEmployeeOnlyFlag = false
      }
    },
    changeHomeTrip(e) {
      if (e == 'YES') {
        this.isHomeTripShow = true
      } else {
        this.isHomeTripShow = false
      }
    },
    employeeOnlyChange(e) {
      if (e == 'YES') {
        this.isRelationshipFlag = false
      } else {
        this.isRelationshipFlag = true //employeeOnly为No时，insturance RelationShip必填
      }
    },
    selectCountry(e) {
      if (e == '156') {
        this.expatLocalList = [
          {
            value: 'Local',
            label: 'Local'
          }
        ]
        this.ExpatOrLocalName = 'Local'
        this.selectEditMsg.expatLocal = 'Local'
        this.selectEditMsg.additionalCommercialInsurance = 'NO'
        this.selectEditMsg.annualPackage = ''
        this.ifEmployeeOnlyFlag = false //additionalCommercialInsurance为Yes的时候，employeeOnly必填
      } else {
        this.expatLocalList = [
          {
            value: 'Expat',
            label: 'Expat'
          }
        ]
        this.ExpatOrLocalName = 'Expat'
        this.selectEditMsg.expatLocal = 'Expat'
        this.selectEditMsg.additionalCommercialInsurance = 'YES'
        this.ifEmployeeOnlyFlag = true
      }
    },
    getData() {
      this.selectEditMsg.hrbp = this.hrbpList
        ? this.hrbpList.label
          ? this.hrbpList.label
          : ''
        : '' //hrbp传值传id  :
      this.selectEditMsg.hiringId = this.jumpParams.hcId //编辑hcId传值
      return { ...this.selectEditMsg }
    },
    checkData() {
      return new Promise((r) => {
        return this.$refs.offerProposalForm.validate((valid) => r(valid))
      })
    },
    async submit() {
      this.selectEditMsg.hrbp = this.hrbpList
        ? this.hrbpList.label
          ? this.hrbpList.label
          : ''
        : '' //hrbp传值传id  :
      this.selectEditMsg.hiringId = this.jumpParams.hcId //编辑hcId传值
      const { data, code, msg } = await this.$Api.induction.editInduction({
        ...this.selectEditMsg
      })

      this.buttonDisable = false
      if (code === 200) {
        this.$message({
          type: 'success',
          message: '修改成功!'
        })

        this.$emit('goback')
      } else {
        this.$message({
          type: 'info',
          message: msg
        })
      }
    },
    async getOfferProposal(hiringId, offerProposalId) {
      this.editStatus = false;
      const dataTarget = await this.$Api.induction.getOfferProposal({
        hiringId: hiringId,
        offerProposalId: offerProposalId
      })
      console.log('dataTarget', dataTarget)
      if (dataTarget && dataTarget.code === 200) {
        const data = dataTarget.data

        //净化数据
        delete data.challengeGroupName
        delete data.chinaFinanceApproved
        delete data.contractPeriodName
        delete data.createDate
        delete data.createUser
        delete data.employeeTypeName
        delete data.hcInformationId
        delete data.hiringId
        delete data.requestId
        delete data.hrbpName
        delete data.jobGradingName
        delete data.noticePeriodName
        delete data.noticePeriodWithinProbationName
        delete data.officeLocationName
        delete data.probationPeriodMonthlyName
        delete data.recruitmentChannelName
        delete data.workSchedulesName
        delete data.workingLocationName
        delete data.updateDate
        delete data.offerStatus
        delete data.taInHand
        if (data.noticePeriod) data.noticePeriod = Number(data.noticePeriod)
        data.country = data.country ? String(data.country) : ''
        data.discretionaryBonusPlan = data.discretionaryBonusPlan
          ? data.discretionaryBonusPlan
          : 'Discretionary Other'
        // specialPoolBonus 默认值No selection。如果有值就取带过来得，否则就用默认值No selection
        data.specialPoolBonusPlan = data.specialPoolBonusPlan
          ? data.specialPoolBonusPlan
          : 'No Selection'

        data.payFrequency = data.payFrequency ? data.payFrequency : 'Monthly'

        // if (data.probationPeriodMonthly == '0') {
        //   this.isMustShow = true
        // } else {
        //   this.isMustShow = false
        // }
        if (data.relocation == 'YES') {
          this.isRelocationShow = true
        } else {
          this.isRelocationShow = false
        }
        if (data.homeTrip == 'YES') {
          this.isHomeTripShow = true
        } else {
          this.isHomeTripShow = false
        }
        if (!data.employeeType) {
          data.employeeType = ''
        }

        this.selectEditMsg = data
        console.log('aaaaa-----a22', this.selectEditMsg.noticePeriod)
        this.bonusChange(this.selectEditMsg.bonusEligible)
        this.expatLocalChange(this.selectEditMsg.expatLocal)
        if (data.expatLocal == 'Expat') {
          this.selectEditMsg.additionalCommercialInsurance = 'YES'
          this.ifEmployeeOnlyFlag = true //additionalCommercialInsurance='YES';.employeeOnly必填
        } else {
          this.selectEditMsg.additionalCommercialInsurance = 'NO'
          this.ifEmployeeOnlyFlag = false
        }
        if (this.selectEditMsg.forEmployeeOnly == 'No') {
          this.isRelationshipFlag = true
        } else {
          this.isRelationshipFlag = false
        }
        console.log('test',this.selectEditMsg.workingLocation);
        //联动location处理
        if (this.selectEditMsg.workingLocation) {
          this.workingLocationList.map((d) => {
            if (d.workLocation == this.selectEditMsg.workingLocation) {
              this.officeList = d.officeLocation
            }
          })
        }

        // 增加用来判断组件销毁时能不能编辑状态   解决数据还没获取赋值完，点击Hiring Brief Info按钮组件销毁   调用编辑传参错误问题
        this.editStatus = true;
      }
    },
    async getOfferProposalSelectedData() {
      const { data, code, msg } =
        await this.$Api.induction.getOfferProposalSelectedData({})
      if (code === 200) {
        data.map((d) => {
          if (d.description == 'hr_job_grading') {
            this.jobGradingList = d.list
          } else if (d.description == 'recruitment_channel') {
            this.recruitmentChannelList = d.list
          } else if (d.description == 'work_schedules') {
            this.workSchedulesList = d.list
          } else if (d.description == 'contract_period') {
            this.contractPeriodList = d.list
          } else if (d.description == 'notice_period_months') {
            this.noticePeriodList = d.list
            console.log('aaaaa-----a', d.list, this.selectEditMsg.noticePeriod)
          } else if (d.description == 'probation_notice_period_days') {
            this.noticePeriodWithinProbationList = d.list
          } else if (d.description == 'hr_employee_type') {
            this.EmployeeTypeList = d.list
          }
        })
      } else {
        this.$message({
          type: 'error',
          message: msg
        })
      }
      this.$Api.induction.dictList({codeSchema:'candidate_previous_company_category'}).then(({code,data})=>{
        if(code === 200){
          this.companyCategorylList = data
        }
      })
    },
    async getLocation() {
      const { data, code, msg } = await this.$Api.induction.getLocation({})
      if (code === 200) {
        // data.map(d=>{
        //   d.officeLocation.map(v=>{
        //     console.log('---------',v)
        //     let officeObj={name:v}

        //   })
        // })
        this.workingLocationList = data
      } else {
        this.$message({
          type: 'error',
          message: msg
        })
      }
    },
    locationChange(e) {
      if (e) {
        this.selectEditMsg.officeLocation = ''
        this.workingLocationList.map((d) => {
          if (d.workLocation == e) {
            this.officeList = d.officeLocation

            console.log('========', this.officeList)
          }
        })
      } else {
        this.officeList = []
        this.selectEditMsg.officeLocation = ''
      }
    },
    async getHRBP(hcId) {
      console.log('aaaaa-----a1', this.selectEditMsg.noticePeriod)
      const { data, code, msg } = await this.$Api.induction.getHRBP({
        id: hcId
      })
      if (code === 200) {
        this.hrbpList = data
        console.log('uuuuu', data)
        this.selectEditMsg.hrbp = data ? (data.value ? data.value : '') : ''
        // hrbp显示用value
      } else {
        this.$message({
          type: 'error',
          message: msg
        })
      }
    },
    async getCountry() {
      const { data, code, msg } = await this.$Api.induction.getCountry({})
      if (code === 200) {
        this.countryList = data
      } else {
        this.$message({
          type: 'error',
          message: msg
        })
      }
    },
    titleCNChange() {
      this.selectEditMsg.titleCnAfterProbation =
        this.selectEditMsg.titleCnDuringProbation
    },
    titleENChange() {
      this.selectEditMsg.titleEnAfterProbation =
        this.selectEditMsg.titleEnDuringProbation
    }
  },
  async beforeDestroy() {
    console.log('----------')
    // 离开此页面得时候暂存填写信息&&不做校验
    if(this.editStatus) this.$emit('temporarySave') //数据获取完   组件销毁才调用编辑接口
  },
  mounted() {}
}
</script>
<style lang="scss" scoped>
.w100 {
  width: 100%;
}
.pr24 {
  padding-right: 24px;
  box-sizing: border-box;
}
.editContainer {
  width: 100%;
  // padding: 0px 10px 0 10px;
  box-sizing: border-box;
  border: 1px solid red;
}
.scrollContainer {
  // height: calc(100vh - 250px) !important ;
  overflow: auto;
  //padding-bottom: 20px;
  box-sizing: border-box;
}
.buttonBottom {
  justify-content: flex-end;
  margin-right: 150px;
  margin-top: 20px;
  .el-button--mini {
    margin-right: 50px;
  }
}
.inductionTitleMsg {
  font-size: 16px;
  color: #303133;
  font-weight: bold;
  padding: 20px 0 0px 0px;
  box-sizing: border-box;
}
.inductionTitleMsg2 {
  font-size: 16px;
  color: #303133;
  font-weight: bold;
  padding: 28px 0 0px 0px;
  box-sizing: border-box;
}
.rowItemIndex {
  margin-top: 12px;
}
.rowItem {
  margin-top: 20px;
}
.flexRow {
  display: flex;
  flex-direction: row;
}
.flexCenter {
  justify-content: center;
  align-items: center;
}
.AlCenter {
  align-items: center;
}
.flexBetw {
  display: flex;
  flex-wrap: wrap;
  .el-form-item {
    width: 23.6%;
    &:last-child {
      padding-right: 0 !important;
      margin-right: 0 !important;
    }
  }
}

.upContainer {
  width: 10px;
  margin-top: 7px;
  justify-content: flex-end;
}
.bottomButton {
  width: 180px;
  padding: 20px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  right: 0;
}
.bottomBox {
  width: 180px;
  padding: 20px;
  box-sizing: border-box;
  z-index: 99;
  position: fixed;
  bottom: 0;
  right: 20px;
}
.flex {
  display: flex;
}
.buttonActive {
  width: 132px;
  height: 30px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 15px;
}
.buttonunActive {
  width: 132px;
  height: 30px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ba9765;
  border-radius: 4px;
  color: #ba9765;
  font-size: 14px;
  line-height: 15px;
}
.lableDetail {
  color: #4e4e50;
  font-size: 14px;
  font-weight: 400;
  font-family: PingFangSC-Regular, PingFang SC;
}
.lableContent {
  color: #545454;
  font-size: 14px;
  // font-weight: 300;
  margin-left: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
}
.lableContent span {
  color: #545454;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
}
</style>
<style lang="scss">
.editInduction-list-page {
  .el-select,
  .el-input-number {
    width: 100%;
  }
  .el-input__inner {
    border: 1px solid #c4c8cf;
  }
  .el-input__inner::placeholder {
    color: #c4c8cf;
    font-size: 14px;
  }
}
</style>
